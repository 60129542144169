<template>
  <div class="hello">
    <editor :disabled="disabled" v-model="editor" :init="init"></editor>
  </div>
</template>

<script>
/**
 * @setEditContent  获取富文本框的值
 * @editorData      对富文本进行回填
 *
 * import EdtTxt from "@/component/editorArea/editorArea";
 * <EdtTxt @setEditContent="setEditContent" :editorData="editorData"/>
 *
 */
import * as qiniu from "qiniu-js";

import { getUploadToken } from "@/api/public";

import tinymce from "tinymce/tinymce";
import Editor from "@tinymce/tinymce-vue";
import "tinymce/themes/silver";
import "tinymce/plugins/code"; // 代码
import "tinymce/plugins/link"; // 链接
import "tinymce/plugins/image"; // 插入上传图片插件
import "tinymce/plugins/media"; // 插入视频插件
import "tinymce/plugins/table"; // 插入表格插件
import "tinymce/plugins/lists"; // 列表插件
import "tinymce/plugins/wordcount"; // 字数统计插件
import "tinymce/icons/default/icons";
import "tinymce/plugins/imagetools"; // 图片
import "tinymce/plugins/paste"; // 张贴
import "tinymce/plugins/help";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/hr";
// import "tinymce/plugins/quickbars";   //聚焦在编辑器中自动出现插入图片和表格
// import "tinymce/plugins/searchreplace";
import "tinymce/plugins/colorpicker";

import "./lineheight";
import "./indent2em";
export default {
  name: "editorArea",
  data() {
    return {
      editor: "",
      mainUrl: "https://qiniuy.tzle1.com/",
      token: "",
      myValue: "",
      init: {
        language_url: "./tinymce/zh_CN.js",
        language: "zh_CN",
        skin_url: "./tinymce/skins/ui/oxide",
        height: 450,
        fontsize_formats:
          "11px 12px 14px 16px 18px 24px 36px 48px 50px 56px 60px 64px",
        lineheight_formats:
          "11px 12px 14px 16px 18px 24px 36px 48px 50px 56px 60px 64px",
        inline_boundaries_selector: "a[href],code,b,i,strong,em",
        plugins:
          "lists image media table wordcount link fullscreen quickbars paste code hr lineheight indent2em help advlist ",
        toolbar:
          "fullscreen   | undo redo | code paste | formatselect alignleft aligncenter alignright alignjustify | link unlink | numlist bullist | image media table | indent2em lineheight fontselect fontsizeselect forecolor backcolor | bold italic underline strikethrough hr | indent outdent blockquote | superscript subscript | removeformat ",
        quickbars_selection_toolbar:
          "removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor",
        branding: true,
        placeholder: "请输入...",
        preview_styles: "font-size color",
        menubar: true,
        mode: "textareas",
        paste_convert_word_fake_lists: false,
        paste_data_images: true,
        //黏贴的回调处理
        paste_preprocess: function(plugin, args) {},
        //图片的回调，包括长传和粘贴的
        images_upload_handler: (blobInfo, success, failure) =>
          this.updateImage(blobInfo, success, failure),
      },
    };
  },
  components: { Editor },
  props: {
    editorData: String,
    disabled: Boolean,
  },
  mounted() {
    tinymce.init({});
  },
  created() {
    // getUploadToken().then((res) => {
    //   this.token = res.data.data;
    // });
  },
  watch: {
    editorData(val) {
      this.editor = val;
    },
    editor(val) {
      this.$emit("setEditContent", val);
    },
  },
  methods: {
    updateImage(blobInfo, success, failure) {
      let that = this;
      let observable = qiniu.upload(
        blobInfo.blob(),
        "love/" + new Date().getTime(),
        that.token,
        {},
        {}
      );
      observable.subscribe({
        complete: (res) => {
          success(that.mainUrl + res.key);
          return;
        },
        error: (res) => {
          failure("错误");
          return;
        },
      });
    },
  },
};
</script>

<style scoped></style>
